import './App.css';

import React, { Component } from 'react';

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      
    }
  }

  Modal() {
    return (
      <>

      </>
    );
  }

  Entry() {
    return (
      <div className="entry">
        <div className="actions">
          <a href="#"><span class="material-symbols-outlined">open_in_new</span></a>
          <a href="#"><span class="material-symbols-outlined">settings_applications</span></a>
        </div>
        <div className="title">
          <span>This is an example title of a book that goes on for a little while</span>
        </div>
        <div className="information">
          <a href="#"><span class="material-symbols-outlined">more_vert</span></a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="app">
        <div className="navbar">
          <div className="left">
            <div className="title">Textbooks & Other Resources</div>
          </div>
          <div className="right">
            <div className="options">
              <button>Filter</button>
              <button>Add</button>
              <button>Remove</button>
            </div>
          </div>
        </div>
        <div className="list">
          <div className="entries">
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
            <this.Entry />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
